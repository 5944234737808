import React from 'react';
import Helmet from 'react-helmet';

import faviconApple from '../favicons/apple-touch-icon.png'
import favicon192 from '../favicons/android-chrome-192x192.png'
import favicon512 from '../favicons/android-chrome-512x512.png'
import faviconICO from '../favicons/favicon.ico'

import '../misc/fontawesome';
import './layout.css';

import Header from './header'
import Footer from './footer'

const Layout = ({children, path}) => (
  <div>
    <Helmet
      title={'Eastwood Property'}
      meta={[
        {name: 'description', content: 'Established in 1996, Eastwood Property constructs new buildings. We also extend and alter buildings for a wide variety of clients.'}
      ]}
    >
      <link href="https://fonts.googleapis.com/css?family=Abel|Lora|Montserrat:600|Open+Sans:300,400" rel="stylesheet"/>
      <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />

      <link rel="icon" type="image/png" sizes="512x512" href={favicon512} />
      <link rel="icon" type="image/png" sizes="192x192" href={favicon192} />
      <link rel="shortcut icon" href={faviconICO} />
      <meta name="format-detection" content="telephone=no"/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/> 
      <meta name="theme-color" content="#222222" />

    </Helmet>
    <div id="AppContainer" style={{backgroundColor: '#333333'}}>
      <Header path={path}/>
      {children}
      <Footer path={path}/>
    </div>
  </div>
);

export default Layout;

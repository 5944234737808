import React, {Component} from 'react';
import Link from 'gatsby-link';
import injectSheet from 'react-jss';
import {HamburgerButton} from 'react-hamburger-button';

import siteLogo from '../images/logos/sitelogo.png';

const styles = {
	navbar: {
		position: 'absolute',
		backgroundColor: 'transparent',
		zIndex: '1000',
		display: 'flex',
		flexFlow: 'row',
		justifyContent: 'space-between',
		width: '100%',
		minWidth: '240px',
		background: 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0))'
	},
	navList: {
		display: 'flex',
		flexFlow: 'row',
		margin: '3em',
		marginLeft: '0em',
		transition: 'opacity 0.2s',
		'@media (max-width: 667px)': {
			width: 0
		}
	},
	navListMobile: {
		display: 'flex',
		flexFlow: 'column',
		width: '100%',
		margin: '0em',
		paddingBottom: '2em',
		justifyContent: 'space-between',
		height: 'calc(90vh - 12em)',
		alignItems: 'center'
	},
	brand: {
		height: '64px',
		margin: '2em 3em',
		backgroundColor: 'rgba(255,255,255,0.)',
		transition: 'all 0.2s',
		'@media (max-width: 767px)': {
			margin: '1em 2em'
		}
	},
	brandBox: {
		display: 'flex'
	},
	navText: {
		color: 'white',
		textDecoration: 'none',
		textTransform: 'uppercase',
		fontFamily: '"Montserrat"',
		paddingBottom: 2,
		fontSize: '0.75em',
		transition: 'all 0.2s'
	},
	navTextMobile: {
		fontSize: '1.25em',
		padding: 0,
		color: 'white',
		textDecoration: 'none',
		textTransform: 'uppercase',
		fontFamily: '"Montserrat"',
		transition: 'all 0.2s'
	},
	navTextActive: {
		borderBottom: '3px solid #F37B1D'
	},
	navTextMobileActive: {
		borderBottom: '4px solid #F37B1D'
	},
	navItem: {
		listStyleType: 'none',
		border: '2px solid transparent',
		padding: '4px 12px',
		margin: 8,
		transition: 'all 0.2s',
		'@media (max-width: 900px)': {
			margin: 4,
			padding: 4
		}
	},
	navItemMobile: {
		listStyleType: 'none',
		border: '2px solid transparent',
		width: '100%',
		textAlign: 'center',
		padding: 32
	},
	navButtonItemMobile: {
		listStyleType: 'none',
		border: '2px solid transparent',
		padding: '4px 0px',
		'@media (max-width: 767px)': {
			textAlign: 'center',
			padding: 32
		}
	},
	navItemMobileActive: {
		backgroundColor: '#333333'
	},
	navButton: {
		border: '2px solid white',
		padding: '4px 8px'
	},
	navButtonMobile: {
		border: '2px solid white',
		padding: '16px 32px'
	},
	navMobile: {
		display: 'none',
		height: '100vh',
		width: '100vw',
		minWidth: '240px',
		backgroundColor: '#222222',
		position: 'fixed',
		overflowY: 'auto',
		top: 0,
		left: 0,
		zIndex: 10000,
		transition: 'opacity 0.4s',
		'@media (max-width: 767px)': {
			display: 'block'
		}
	},
	hamburger: {
		pointerEvents: 'none',
		padding: '2.5em',
		position: 'absolute',
		transition: 'opacity 0.2s',
		top: 0,
		right: 0,
		opacity: 0,
		'@media (max-width: 767px)': {
			opacity: 1,
			pointerEvents: 'auto'
		}
	},
	hidden: {
		opacity: '0',
		transition: 'opacity 0.4s',
		pointerEvents: 'none'
	},
	hiddenMobile: {
		'@media (max-width: 767px)': {
			opacity: '0',
			transition: 'opacity 0.2s',
			pointerEvents: 'none',
			display: 'none'
		}
	}
};

class Header extends Component {
	constructor() {
		super();
		this.state = {open: false};
	}

	openMenu() {
		if (typeof document !== 'undefined') {
			document.getElementById('___gatsby').setAttribute('class', 'overflow-hidden');
			window.scrollTo(0, 0);
			if (document.getElementById('navbarMobile')) {
				document.getElementById('navbarMobile').scrollTop = 0;
			}
		}
	}

	closeMenu() {
		if (typeof document !== 'undefined') {
			document.getElementById('___gatsby').setAttribute('class', '');
			if (document.getElementById('navbarMobile')) {
				document.getElementById('navbarMobile').scrollTop = 0;
			}
			window.scrollTo(0, 0);
		}
	}

	handleClick() {
		this.setState({
			open: !this.state.open
		});
	}

	render() {
		const {classes, path} = this.props;

		this.state.open ? this.openMenu() : this.closeMenu();

		return (
      <nav className={classes.navbar}>
          <div>
              <Link to="/"><img alt="Site Logo" className={classes.brand} src={siteLogo}></img></Link>
          </div>

          <div id="navbarMobile" className={classes.navMobile + (this.state.open ? ' ' : ' ' + classes.hidden)}>
            <div className={classes.brandBox}>
              <Link to="/">
                <img alt="Site Logo" className={classes.brand} src={siteLogo}></img>
              </Link>
            </div>

            <div className={classes.hamburger} onClick={this.handleClick.bind(this)}>
              <HamburgerButton
                open={this.state.open}
                height={20}
                width={26}
                color="white"
              />
            </div>

            <ul className={classes.navListMobile}>
                  <li className={classes.navItemMobile + ' ' + (path === "/" ? classes.navItemMobileActive : '')}>
                      <Link className={classes.navTextMobile + ' ' + (path === "/" ? classes.navTextMobileActive : '')} to="/">Home</Link>
                  </li>
                  <li className={classes.navItemMobile  + ' ' + (path === "/projects" ? classes.navItemMobileActive : '')}>
                      <Link className={classes.navTextMobile + ' ' + (path === "/projects" ? classes.navTextMobileActive : '')} to="/projects">Projects</Link>
                  </li>
                  <li className={classes.navItemMobile + ' ' + (path === "/about" ? classes.navItemMobileActive : '')}>
                      <Link className={classes.navTextMobile + ' ' + (path === "/about" ? classes.navTextMobileActive : '')} to="/about">About Us</Link>
                  </li>
                  <li className={classes.navItemMobile + ' ' + (path === "/contact" ? classes.navItemMobileActive : '')}>
                      <Link className={classes.navTextMobile + ' ' + (path === "/contact" ? classes.navTextMobileActive : '')} to="/contact">Contact</Link>
                  </li>
                  <li className={classes.navButtonItemMobile}>
                    <div className={classes.navButtonMobile}>
                      <a className={classes.navTextMobile} href="https://app.eastwoodproperty.com.au">Login / Inductions</a>
                    </div>
                  </li>                  
              </ul>
          </div>

          <div id="navbarNav">
              <ul className={classes.navList + ' ' + classes.hiddenMobile}>
                  <li className={classes.navItem}>
                      <Link className={classes.navText + ' ' + (path === "/" ? classes.navTextActive : '')} to="/">Home</Link>
                  </li>
                  <li className={classes.navItem}>
                      <Link className={classes.navText + ' ' + (path === "/projects" ? classes.navTextActive : '')} to="/projects">Projects</Link>
                  </li>
                  <li className={classes.navItem}>
                      <Link className={classes.navText + ' ' + (path === "/about" ? classes.navTextActive : '')} to="/about">About Us</Link>
                  </li>
                  <li className={classes.navItem}>
                      <Link className={classes.navText + ' ' + (path === "/contact" ? classes.navTextActive : '')} to="/contact">Contact</Link>
                  </li>
                  <li className={classes.navItem + ' ' + classes.navButton}>
                      <a className={classes.navText} href="https://app.eastwoodproperty.com.au">Login / Inductions</a>
                  </li>                  
              </ul>

              <div className={classes.hamburger}>
                <HamburgerButton
                  open={this.state.open}
                  onClick={this.handleClick.bind(this)}
                  height={20}
                  width={26}
                  color="white"
                />
              </div>

          </div>

      </nav>
		);
	}
}

export default injectSheet(styles)(Header);
